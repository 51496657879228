/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // add hover script to hide submenu if there is one on display
        console.log('init init');
        $('#menu-menu-1 > li').hover(
          function(event) {
            console.log('mousenter');
            /* Stuff to do when the mouse enters the element */
            // check to see if this element has the submenu on show
            var el = $(event.target).parent();
            var el2 = $(event.target).parent().parent().parent();

            if (el.hasClass('current-menu-item') || el.hasClass('current-menu-parent'))
            {
              return;
            } else if (el2.hasClass('current-menu-item') || el2.hasClass('current-menu-parent'))
            {

              return
            }

            $('.current-menu-item .sub-menu').addClass('hide-sub');
            $('.current-menu-parent .sub-menu').addClass('hide-sub');
            $('.current-menu-item .sub-menu-background').addClass('hide-sub');
            $('.current-menu-parent .sub-menu-background').addClass('hide-sub');

          },
          function(event) {
            console.log('mouseout');
            /* Stuff to do when the mouse leaves the element */
            $('.hide-sub').removeClass('hide-sub');
          });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'location' :
    {
      init: function()
      {
        $('.hero-map-widget__widget').each(function(){
          // create map
          map = new_map($(this), false, 16);

        });
      }
    },
    'our_world': {
      init: function()
      {
        async.parallel([
        /////////////////
        // load instagram pics
        /////////////////
        function(callback)
        {

            var feed = new Instafeed({
              get: 'user',
              // userId: 285176398,
              // userId: 2270829251,
              userId: 1573665389, // moro user id
              clientId: '70f7f6094a174249a6ea2c3ca20ba32a',
              accessToken: '1573665389.1677ed0.062c75c9ffc74039934c0decbc36830b',
              success: function(data)
              {
                console.log('instagram loaded');
                callback(null, data.data);
              },
              error: function(error)
              {
                console.log(error);
                callback(null, null);
              },
              mock: true,
              sortBy: 'most-recent',
              limit: 9,
            });
            feed.run();
        },
        /////////////////
        // load tweets
        /////////////////
        function(callback)
        {
          // $.get( "/twitterfeed/gettwitterfeed.php", function( data )
          // {
          //   console.log('tweets loaded');
          //   callback(null, JSON.parse(data));
          // });
          var config3 = {
            // "id": '502160051226681344',
            "profile": {"screenName": 'RestaurantMoro'},
            "domId": 'example3',
            "maxTweets": 9,
            "enableLinks": true,
            "showImages": true,
            "showRetweet": false,
            "customCallback": function(tweets)
            {
              // console.log('tweets loaded', tweets);
              callback(null, tweets);
            },
          };
         // function handleTweets()
          twitterFetcher.fetch(config3);
          // callback(null, []);
        },
        /////////////////
        // load blog posts
        /////////////////
        function(callback)
        {
          // $.get( "/?feed=json", function( data )
          // {
          //   console.log('blogs loaded');
          //   callback(null, data);
          // });
          //
          callback(null, null);
        }

        ],
        // callback
        function(err, results){

            console.log('results', results);

            var display = [], // array for elemtns to display
                tw_display = [], // array for tweet elemtns to display
                inst_display = [], // array for tweet elemtns to display
                blog_display = [], // array for tweet elemtns to display
                i=0, // iterator
                item, // item variable
                arr; // temp variable for the array of results we are processing
            // get the instagram photos from the results arr
            if (results[0])
            {
              console.log('add instagrams to display');
              arr = results[0];
              for (i;i<arr.length;i++)
              {
                item = arr[i];
                // push the bits of info we need to the display array
                inst_display.push(
                {
                  type: 'instagram',
                  time: new Date(Number(item.created_time*1000)),
                  image: item.images.standard_resolution.url,
                  link: item.link
                });
              }
            }

            // get the tweets
            if (results[1])
            {
              console.log('add tweets to display',results[1]);
              arr = results[1];

              for (i=0;i<arr.length;i++)
              {
                item = arr[i];
                // console.log('add tweet', item);
                // push the bits of info we need to the display array
                tw_display.push(
                {
                  type: 'tweet',
                  text: item,
                  // time : new Date(item.created_at)
                });
              }
            }

            // get the blogs

            if (results[2])
            {
              console.log('add blogs to display');
              arr = results[2];
              for (i=0;i<arr.length;i++)
              {
                item = arr[i];
                // push the bits of info we need to the display array
                blog_display.push(
                {
                  type: 'blog',
                  text: item.title,
                  time : new Date(item.date),
                  // with the exceprt we need to strip out the link at the end
                  summary: item.excerpt.substring(0, item.excerpt.indexOf('&hellip;')),
                  link: item.permalink
                });
              }
            }



            // sort and display chronologically (thereby mixing them up)

            var blog_display_sorted = _.sortBy(blog_display, function(num){return 1-num.time;});
            console.log('blog',blog_display_sorted, blog_display);
            var tw_display_sorted = _.sortBy(tw_display, function(num){return 1-num.time;});
            console.log('tweet',tw_display_sorted, tw_display);
            var inst_display_sorted = _.sortBy(inst_display, function(num){return 1-num.time;});
            console.log('inst',inst_display_sorted, inst_display);
            var display_sorted = [];

            i = 0;
            while (blog_display_sorted.length || tw_display_sorted.length || inst_display_sorted.length)
            // while (i++ < 20)
            {
              var el;
              switch(Math.floor(Math.random() * 3))
              {
                case 0:
                    el = blog_display_sorted.shift()
                    break;
                case 1:
                    el = tw_display_sorted.shift()
                    break;
                case 2:
                    el = inst_display_sorted.shift()
                    break;
              }
              if (el)
              {
                display_sorted.push(el);
              }
              console.log(blog_display_sorted.length,tw_display_sorted.length,inst_display_sorted.length);
            }

            console.log('display_sorted',display_sorted);

            // shuffle display order
            // var display_sorted = _.shuffle(display);


            var html='';
            // iterate through and add in HTML (could be done with a templating engine {{i guess}})
            _.each(display_sorted, function (item)
            {
              switch (item.type)
              {
                case 'instagram' :
                  html += '<a href="'+item.link+'" class="mosaic-content__item instagram ">'+
                          '  <img src="'+item.image+'" />'+
                          '</a>';
                  break;
                case 'tweet' :
                  html += '<div href="'+item.link+'" class="mosaic-content__item tweet content-box">'+
                          // '  <h2>@RestaurantMoro</h2>'+
                          // '  <p class="date">'+moment(item.time).format('Do MMMM YYYY')+'</p>'+
                          // '  <p>'+item.text+'</p>'+
                          item.text+
                          '</div>';
                  break;
                case 'blog' :
                  html += '<a href="'+item.link+'" class="mosaic-content__item blog content-box case_study_large">'+
                          '  <h2>Blog</h2>'+
                          '  <p class="date">'+moment(item.time).format('Do MMMM YYYY')+'</p>'+
                          '  <p>'+item.text+'</p>'+
                          '  <p class="summary">'+item.summary+'</p>'+
                          '</a>';
                  break;
              }

            });
            // console.log('html to render', html);
            // take loading class off so that the loading symbol disappears
            $('.mosaic-content').removeClass('loading').html(html);
            // do the isotope layout
            // Moro.do_isotope();
            // Mask.handle_address_hash();
            $(window).resize(function(event)
            {
              var winW = $(window).width()
              console.log('winW:', winW, ' Moro.is_isotoped:', Moro.is_isotoped);
              if(winW > 767 && !Moro.is_isotoped)
              {
                Moro.do_isotope()
              } else if (winW <= 767 && Moro.is_isotoped)
              {
                Moro.kill_isotope()
              }
            }).trigger('resize');
        });

      }
    }
  };

var Moro =
{
  is_isotoped: false,
  do_isotope: function()
  {
    console.log('do_isotope');
    Moro.is_isotoped = true;
    iso = new Isotope( '.mosaic-content', {
      itemSelector: '.mosaic-content__item',
      // layoutMode: 'maskRows'
    });
  },
  kill_isotope: function ()
  {
    iso.destroy();
    Moro.is_isotoped = false;
    console.log('kill_isotope');
  },
  filter_button: function (event, scope, filter, hash, el)
  {
    event.preventDefault();
    iso.maskArrange({ filter: filter, el: el});
    location.hash = "#"+hash;
    $('#subnav .selected').removeClass('selected');
    $(scope).addClass('selected');
  },
  reset_filter: function (event, hash)
  {
    event.preventDefault();
    iso.maskReset({ filter: '*' });
    location.hash = "#"+hash;
    $('#subnav .selected').removeClass('selected');
  }
};

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

   // create and manage map

  /*
  *  new_map
  *
  *  This function will render a Google Map onto the selected jQuery element
  *
  *  @type  function
  *  @date  8/11/2013
  *  @since 4.3.0
  *
  *  @param $el (jQuery element)
  *  @return  n/a
  */

  function new_map( $el, $centerMap, $zoom ) {
    // var
    var $markers = $('.marker'),
        $centerMap = ($centerMap)? $centerMap : false,
        $zoom = ($zoom)? $zoom : 14;

    //

    // vars
    var args = {
      zoom    : $zoom ,
      center    : new google.maps.LatLng(51.525840, -0.108962),
      mapTypeId : google.maps.MapTypeId.ROADMAP,
      backgroundColor: "#00a072",
      styles : [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#c1c1c1"},{"visibility":"on"}]}]
    };


    // create map
    var map = new google.maps.Map( $el[0], args);

    // console.log('map markers', $markers);
    // add markers
    add_markers($markers, map)

    map.panBy(0, -80);

    // map.setZoom( 14);

    // if ($centerMap)
    // {
    //   center_map( map );
    // } else

    return map;

  }
  /*
*  add_markers
*
*  This function adds all markers to the map for all visible elements
*
*  @type  function
*  @date  8/11/2013
*  @since 4.3.0
*
*  @param $el (jQuery element)
*  @param map (Google Map object)
*  @return  n/a
*/
function add_markers($el, map)
{
  console.log('add_markers el', $el);
  // var
  var $markers = $el; //.filter(':visible');

  // add a markers reference
  map.markers = [];
  // infoWindow = new google.maps.InfoWindow();

  infoBubble = new InfoBubble({
                    shadowStyle: 1,
                    padding: 0,
                    backgroundColor: '#00a072',
                    borderRadius: 0,
                    arrowSize: 10,
                    borderWidth: 0,
                    disableAutoPan: true,
                    disableAnimation: true,
                    maxWidth: 175,
                    maxHeight: 175
                    });
  // add markers
  $markers.each(function(){

      add_marker( $(this), map, infoBubble );
      // console.log($(this));
  });
}
/*
*  clear_markers
*
*  This function adds all markers to the map for all visible elements
*
*  @type  function
*  @date  8/11/2013
*  @since 4.3.0
*
*  @param map (Google Map object)
*  @return  n/a
*/
function clear_markers(map)
{
  for (var i = 0; i < map.markers.length; i++) {
    map.markers[i].setMap(null);
  }
  map.markers = [];
}

  /*
  *  add_marker
  *
  *  This function will add a marker to the selected Google Map
  *
  *  @type  function
  *  @date  8/11/2013
  *  @since 4.3.0
  *
  *  @param $marker (jQuery element)
  *  @param map (Google Map object)
  *  @return  n/a
  */

  function add_marker( $marker, map, infoWindow ) {

    console.log('add_marker to', $marker.attr('data-lat'), $marker.attr('data-lng'));
    // var
    var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

    // create marker
    var marker = new google.maps.Marker({
        position  : latlng,
        map     : map,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 10,
          fillColor: '#00a072',
          fillOpacity: 1,
          strokeOpacity: 0,
          strokeWeight: 0,
        },
    });

    // add to array
    map.markers.push( marker );

    // if marker contains HTML, add it to an infoWindow
    if( $marker.attr('data-title') )
    {
      infoWindow.setContent('<div id="map-info1" class="map-info__container"><a class="map-info" href="'+$marker.attr('data-link')+'" target="_blank"><h2 class="map-info__title">'+$marker.attr('data-title')+'</h2>'+$marker.attr('data-content')+'</a></div>');
      infoWindow.open( map, marker );
    }

  }

  /*
  *  center_map
  *
  *  This function will center the map, showing all markers attached to this map
  *
  *  @type  function
  *  @date  8/11/2013
  *  @since 4.3.0
  *
  *  @param map (Google Map object)
  *  @return  n/a
  */

  function center_map( map ) {

    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each( map.markers, function( i, marker ){

      var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

      bounds.extend( latlng );

    });

    // only 1 marker?
    if( map.markers.length == 1 )
    {
      // set center of map
        map.setCenter( bounds.getCenter() );
        map.setZoom( 15 );
    }
    else
    {
      // fit to bounds
      map.fitBounds( bounds );
    }

  }

  /*
  *  document ready
  *
  *  This function will render each map when the document is ready (page has loaded)
  *
  *  @type  function
  *  @date  8/11/2013
  *  @since 5.0.0
  *
  *  @param n/a
  *  @return  n/a
  */
  // global var
  var map = null;

})(jQuery); // Fully reference jQuery after this point.
